import React from 'react';

import './styles.css';

export const LineDotContainer = ({ className, containerType = "downRight" , children }) => {

    const positionClasses = containerType;
    return (
        <div className={ `partners-container ${positionClasses} ${className}`}>
            
            <div className={ `circle ${positionClasses}` }/>
            { children }
            <div className={`rectangle-${positionClasses}`}/>
            
        </div>
    )
};
