import React from 'react';

import Swal from 'sweetalert2';

import styles from './styles.module.css';
import {Input, Paragraph, SectionTitle, TextArea, Title, Button} from "../../components";

const validateEmail = (email) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

export const ContactUs = () => {
    const validateForm = async () => {
        const name = document.getElementById("contact-form-name").value;
        const email = document.getElementById("contact-form-email").value.trim();
        const message = document.getElementById("contact-form-message").value;
        const termsChecked = document.getElementById("contact-form-terms").checked;

        if( !name || name.length < 2 ){
            Swal.fire(
                'Missing information',
                'Please provide your name',
                'error'
            );

            return;
        }

        if( !email || email.length < 5 || !validateEmail(email)){
            Swal.fire(
                'Missing information',
                'Please provide your email address',
                'error'
            );

            return;
        }

        if( !message || message.length < 1 ){
            Swal.fire(
                'Missing information',
                'Please provide a message',
                'error'
            );

            return;
        }

        if( !termsChecked ){
            Swal.fire(
                'Terms and Conditions',
                'Please accept the Terms and Conditions',
                'error'
            );

            return;
        }

        console.log("process.env. : " , process.env)
        const response = await fetch( process.env.LANDING_PAGE_CONTACT_FORM_URL , {
            method : "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                name,
                email,
                message
            })
        })

        console.log("response :" , response)
        console.log("responso status" , response.status) 

        if( response.ok ){
            Swal.fire(
                'Thank you!',
                "We'll get back to you soon",
                'success'
            );
    
            document.getElementById("contact-form-name").value = ""
            document.getElementById("contact-form-email").value = ""
            document.getElementById("contact-form-message").value = ""
        }
        else {
            Swal.fire(
                'Something went wrong',
                'Please try to submit your message again',
                'error'
            );
        }
        

    }

    return (
        <>
            <a name="contact-us"/>

            <div className={ styles.container }>
                <SectionTitle name="Contact"/>
                <div className={styles.innerContainer}>
                    <div className={ styles.styledAddressSection}>
                    <div className={styles.border}/>
                    <div className={ styles.addresses }>
                        <div className={styles.address}>
                            <div className={styles.addressCircle}/>
                            <Title size="small" weight="thin">info@calibrateconsulting.com</Title>
                        </div>
                        <div className={styles.address}>
                            <div className={styles.addressCircle}/>
                            <Title size="small" weight="thin">+44(0) 3330 112844</Title>
                        </div>
                        <div className={styles.address}>
                            <div className={styles.addressCircle}/>
                            <Title size="small" weight="thin">London</Title>
                            <Paragraph className={styles.addressText}>
                                5th Floor, The Spice Building, <br/>
                                8 Devonshire Square,<br/>
                                London EC2M 4PL
                            </Paragraph>
                        </div>
                        <div className={styles.address}>
                            <div className={styles.addressCircle}/>
                            <Title size="small" weight="thin">Leicester</Title>
                            <Paragraph className={styles.addressText}>
                                First Floor, Provincial House,<br/>
                                37 New Walk,<br/>
                                Leicester LE1 6TE
                            </Paragraph>
                        </div>
                        <div className={styles.address}>
                            <div className={styles.addressCircle}/>
                            <Title size="small" weight="thin">New York</Title>
                            <Paragraph className={styles.addressText}>
                                5 Penn Plaza,<br/>
                                23rd Floor Suite# 23013,<br/>
                                New York NY 10001
                            </Paragraph>
                        </div>


                    </div>
                    </div>
                    <div className={ styles.contactForm }>
                        <Title size="medium" weight="thin">Talk to the Calibrate team</Title>
                        <Paragraph className={styles.noFlex}>
                            Ask about our work, how we could support your business, or joining the Calibrate team
                        </Paragraph>
                        <Input label="Name" id="contact-form-name"/>
                        <Input label="Email" id="contact-form-email"/>
                        <TextArea label="Message" id="contact-form-message"/>
                        <div className={ styles.contactCheckbox }>
                            <input type="checkbox" name="contact-terms" id="contact-form-terms"/>
                            <label htmlFor="contact-terms">
                                Please see our Privacy Policy for information on how we may use any personal information
                                that you provide to us. By checking this box, you have confirmed that you have read and
                                agreed to our <a href="/terms-and-conditions">Terms</a> and <a href="/privacy-policy">Privacy Policy</a>
                            </label>

                        </div>
                        <Button onClick={validateForm}>
                            SUBMIT MESSAGE
                        </Button>
                    </div>
                </div>

            </div>
        </>
    )
}