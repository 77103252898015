import React from 'react';

import styles from './styles.module.css';

export const SectionTitle = ({ name }) => (
    <div className={styles.container}>
        <img className={styles.icon} src="/icons/brown-line.svg"/>
        <span>{ name  }</span>
    </div>
)
