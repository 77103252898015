import React from 'react';

import styles from './styles.module.css';


import {Title, Paragraph, Accordion, SectionTitle} from '../../components'
import { WorkCard } from './WorkCard/WorkCard.js'

import { sections } from './content.js';

const accordionSections = [];

sections.forEach( section => {
    accordionSections.push({
        title :  section.title,
        content : (
            <WorkCard title={section.title} badges={section.badges} sections={section.sections}/>
        )
    })
})

export const OurWork = () => {
    return (
        <>
            <a name="our-work"/>
            <div className={styles.container}>
                <SectionTitle name={"Our Work"}/>
                <Title className={styles.title} weight="thin">Committed to our clients, craft and creating impact</Title>
                <Paragraph className={styles.subtitle}>We treat each customer and each challenge with the same care, curiosity<br/> and comprehensive commitment to creating measurable impact</Paragraph>
                <Accordion
                    sections={accordionSections}
                />
            </div>
        </>
    )
}