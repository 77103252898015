import React from 'react';

import styles from './styles.module.css';

import {Title, Paragraph, Badge, Button} from '../../../components';

export const WorkCard = ({ title , badges=[] , sections=[] }) => {
    return (
        <div className={styles.container} key={title}>
            <div className={styles.leftColumn}>
                <Title style={{ flex: 1}} className={`${styles.flex}`} size="small">{title}</Title>
                {
                    badges.map( (label , index) => (
                        <Badge key={`b${index}`} label={label}/>
                    ))
                }
                <div className={styles.contactSection}>
                    <Paragraph>If you’d like to hear more</Paragraph>
                    <Button onClick={ () =>  window.location.href = "#contact-us" }>Contact us</Button>
                </div>

            </div>
            <div className={styles.rightColumn}>
                {
                    sections.map(({ title , content} , index) => (
                        <>
                            <Title key={`t${index}`} size="tiny" weight="bold">{title}</Title>
                            <Paragraph key={`p${index}`}>{content}</Paragraph>
                        </>
                    ))
                }
            </div>
            <div className={styles.mobileContactSection}>
                <Paragraph>If you’d like to hear more</Paragraph>
                <Button onClick={ () =>  window.location.href = "#contact-us" }>Contact us</Button>
            </div>

        </div>
    )
}
