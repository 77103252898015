import React, {createRef, useEffect, useState} from 'react';

import styles from './styles.module.css';


export const StickyNavBar = () => {
    const stickyNavBar = createRef();
    const [navBarDisplayed, setNavBarDisplayed] = useState(false);
    const [ scrollPosition , setScrollPosition] = useState(0);

    // useEffect(() => {
    //

    //     const timer = setInterval(() => {
    //         if(!stickyNavBar || !stickyNavBar.current){
    //             return
    //         }
    //         const { top } = stickyNavBar.current.getBoundingClientRect();
    //
    //         if( top === 0 ){
    //             setNavBarDisplayed(true);
    //         }
    //         else{
    //             setNavBarDisplayed(false)
    //         }
    //
    //     } , 100);
    //
    //     return () => {
    //         clearInterval(timer)
    //     }
    // });

    useEffect( () => {

        const handler = ( event ) => {
            setScrollPosition(( ( window.scrollY ) / ( document.body.clientHeight )) * 100 * 0.95 );



            const { top } = document.getElementById("sticky-navbar").getBoundingClientRect();
            console.log("top : " , top);

            if( top === 0 ){
                setNavBarDisplayed(true);
            }
            else{
                setNavBarDisplayed(false)
            }
        };

        document.addEventListener('scroll' , handler);
        return () => {
            window.removeEventListener('scroll' , handler)
        }
    } , [])

    return (
        <div className={ `${ styles.container } ${ navBarDisplayed && styles.pinned}`} ref={ stickyNavBar } id={"sticky-navbar"}>
            <div className={styles.logoContainer}>
                <img src="/calibrate-logo-black.svg"/>
            </div>
            <div className={styles.flex3}>
                <div className={styles.border}/>
                <div className={styles.scrollIndicator}
                    style={{
                        left : `${scrollPosition}%`
                    }}
                />
                <div className={styles.links}>
                    <a href='#about-us'>
                        <div className={styles.circle}/>
                        About Us
                    </a>
                    <a href='#capabilities'>
                        <div className={styles.circle}/>
                        Capabilities
                    </a>
                    <a href='#our-work'>
                        <div className={styles.circle}/>
                        Our Work
                    </a>
                    <a href='#contact-us'>
                        <div className={styles.circle}/>
                        Contact
                    </a>
                </div>

            </div>


        </div>
    )
}