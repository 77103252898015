import React, { useState } from 'react';

import styles from './styles.module.css'

export const MobileNavBar = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const goToPage = (link) => {;               //Save down the URL without hash.
        window.location.href = `#${link}`;                 //Go to the target element.
        //history.replaceState(null,null,url);   //Don't like
        setMenuIsOpen(false);
    }

    return (
        <div className={ `${ styles.container } ` } >
            <img className={styles.ham}
                 src={"/icons/menu.svg"}
                 onClick={ () => setMenuIsOpen(true)}
            />
            <nav className={ `${ styles.navbar } ${ menuIsOpen && styles.showNav} ` }>
                <div className={styles.topSection}>
                    <img className={ styles.logo} src="/calibrate-logo.svg"/>
                    <img className={styles.close}
                         src={"/icons/close.svg"}
                         onClick={ () => setMenuIsOpen(false)}
                    />
                </div>
                <div className={styles.border}/>
                <ul className={styles.links}>
                    <li>
                        <div className={styles.circle}/>
                        <a className={ styles.menuLink } onClick={ () => goToPage("about-us")}>
                            About Us
                        </a>
                    </li>
                    <li>
                        <div className={styles.circle}/>
                        <a className={ styles.menuLink } onClick={ () => goToPage("capabilities")}>
                            Capabilities
                        </a>
                    </li>
                    <li>
                        <div className={styles.circle}/>
                        <a className={ styles.menuLink } onClick={ () => goToPage("our-work")}>
                            Our Work
                        </a>
                    </li>
                    <li>
                        <div className={styles.circle}/>
                        <a className={ styles.menuLink } onClick={ () => goToPage("contact-us")}>
                            Contact
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}