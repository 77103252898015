import React from 'react';

import styles from './styles.module.css';
import { MobileNavBar } from '../../components'

export const NavBar = () => {
    return (
        <div className={ styles.container}>
            <div className={styles.logoContainer}>
                <a href="/">
                    <img src="/calibrate-logo.svg"/>
                </a>

            </div>
            <MobileNavBar/>
            <div className={styles.flex3}>
                <div className={styles.border}/>
                <div className={styles.links}>
                    <a href='/#about-us'>
                        <div className={styles.circle}/>
                        About Us
                    </a>
                    <a href='/#capabilities'>
                        <div className={styles.circle}/>
                        Capabilities
                    </a>
                    <a href='/#our-work'>
                        <div className={styles.circle}/>
                        Our Work
                    </a>
                    <a href='/#contact-us'>
                        <div className={styles.circle}/>
                        Contact
                    </a>
                </div>
            </div>
        </div>
    )
}