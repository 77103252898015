export const sections = [
    {
        title : "Delivering self-service analytics, empowering business users and driving innovative decision-making",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "Senior executives at a state-owned oil and gas company were struggling to efficiently harvest and analyse data, generated from myriad sources in different formats across various business units. As a result, data often had to be mined using a number of applications and analysed manually. This was time consuming, and also resulted in inconsistencies and inaccuracies in reporting due to human error."
            },
            {
                title : "Approach",
                content : "Calibrate’s data architects harmonised and optimised the data from multiple sources across business units, and configured a bespoke Qlik application incorporating standard industry metrics and tailored criteria. Dashboards were created to enable users to visualise results in response to a vast array of key strategic requirements, and drill down for user-specific detail to support data-driven decision-making."
            },
            {
                title : "Result",
                content : "By developing a single, common application, we facilitated the analysis of end-to-end data across the entire business. This provides users with access to reliable, real-time results, and empowers them to make bold and innovative decisions to take the company forward with a competitive edge. We continue to provide ongoing support, maintenance and enhancement expertise, allowing the applications to evolve with the client strategy and business needs."
            }
        ]
    },
    {
        title : "Identifying priorities, delivering effective reporting, facilitating meaningful deliberation and driving business objectives",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "The trading desk of a leading global investment bank was highly dissatisfied with the quality and scope of the monthly MIS reporting, received from the bank’s finance team. Monthly meetings were unproductive and had been stopped, due to the lack of consequential data and the absence of any meaningful insights in existing reporting."
            },
            {
                title : "Approach",
                content : "Calibrate identified high-priority areas for finance MIS reporting, as well as undertaking a cross-divisional review to encompass a broader range of evolving business and market metrics. Leveraging existing infrastructure where possible, a new, user-friendly reporting system was developed — with an emphasis on automation, accuracy, relevance and visualisation."
            },
            {
                title : "Result",
                content : "Incorporating an expanded scope and a variety of performance metrics, the new automated and accurate monthly MIS reporting allowed the links and relationships between the balance sheet, P&L, capital requirements and business performance to be analysed. This provided a solid basis for productive discussions between the trading desk and finance, empowering executives to drive the business and enhancing senior management controls."
            }
        ]
    },
    {
        title : "Optimising project analytics, tracking spending and performance to quantify ROI and deliver cost savings",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "A global top-tier bank experienced difficulties quantifying achieved vs. planned ROI in relation to its vast portfolio of projects. With costing decisions based around inconsistent, inaccurate data derived from numerous sources in multiple formats, executives were unable to adequately track project spending or assess the successful achievement of project objectives in terms of benefits and savings."
            },
            {
                title : "Approach",
                content : "Calibrate synchronised the data generated across multiple sources and geographies, and created a single, self-service Qlik application for project management analytics. With a simplified data-capture interface, the ability to produce ad hoc reports and a dashboard for visualising key metrics, users were able to readily evaluate project spending and performance, swiftly identifying anomalies and areas for improvement."
            },
            {
                title : "Result",
                content : "The increased visibility of accurate, reliable data on costs and outcomes transformed and optimised ROI tracking for all new projects, leading to more constructive data analysis and enhancing internal decision-making processes. As a direct consequence, headcount expenditure was reduced by 15%, equating to millions of pounds in overall cost savings across the bank’s project portfolio."
            }
        ]
    },
    {
        title : "Streamlining processes to save time and money, strengthen internal controls and deliver project management objectives",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "A tier-2 investment bank was rolling out a global project to build a centralised data warehouse for users across finance, risk and operations — with several related initiatives for a consolidated sub-ledger, centralised reconciliation platform and static data services. Due to the complexities inherent in a project of such size and scope, progress was slower than originally predicted and project management deadlines were continually delayed."
            },
            {
                title : "Approach",
                content : "Calibrate finance specialists were embedded in the bank’s core project management and business analyst teams to swiftly identify and remediate implementation inefficiencies, including the elimination of duplicate legacy processes. We designed a new platform and introduced Qlik data visualisation, configuring a range of bespoke interactive dashboards, giving users access to up-to-date information while managing processes more effectively."
            },
            {
                title : "Result",
                content : "The decommissioning of legacy systems and streamlining of processes facilitated a meaningful uptick in progress, realising significant cost and time savings and supporting the achievement of project goals. In addition to delivering on the original data warehousing objectives, internal controls were strengthened — the bank is considering further opportunities aimed at centralising and standardising its data operations."
            }
        ]
    },
    {
        title : "Normalising processes, building a dashboard to ensure accurate reconciliations and deliver robust reporting",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "To strengthen reporting, a global bank needed a unified, cohesive process and overarching central engine to perform automated, accurate reconciliations between front, middle and back office systems. 100+ existing systems and processes were in place, with an equivalent number of reports and inaccuracies. The enterprise data warehouse team also lacked the capacity to build the logic into their workflow, and the cost was prohibitive."
            },
            {
                title : "Approach",
                content : "Calibrate embarked on a normalisation process to ensure that all data and structures were labelled consistently. To avoid the time and cost-intensive endeavour of rebuilding the entire pipeline, we built on an extra layer to capture all trades flowing through the original process. A matching engine was designed, with a key that allocated unique identifiers to trades, which were aligned with the front office and mapped to the back office — highlighting any breaks."
            },
            {
                title : "Result",
                content : "Calibrate built a multi-tiered suite of applications to extract relevant data from disparate systems. Myriad feeds were normalised in the process, ensuring efficiency and consistency, and the dashboard allowed in-depth analysis and the ability to flag breaks clearly and swiftly. The application was used by operations and product-control staff, significantly reducing time and costs and optimising the bank’s risk-management strategy."
            }
        ]
    },
    {
        title : "Enhanced online portal for liquidity management, delivering greater transparency for effective decision-making",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "A top-tier bank wanted to attract new customers and enhance the user experience for corporate clients, while tracking cash and liquidity holdings and KPIs via an online portal and interactive dashboard. Internally, the portal needed to provide relationship managers with deposit recommendations for their clients, as well as perform client clustering for product recommendations and enable analysis relating to sensitivity, churn rate and wallet share."
            },
            {
                title : "Approach",
                content : "We overcame significant challenges to successfully navigate the bank’s internal platforms, processes, and regulatory complexities. Qlik Sense was used to gather and transform data into a usable format, while web-embedded mashups and Node.js were used to create the framework for bespoke visualisations, and density-based clustering algorithms were incorporated with other data sets to predict suitable deposit recommendations."
            },
            {
                title : "Result",
                content : "The enhanced online portal dramatically increased transparency, enhancing visibility around daily liquidity positions, control of funding solutions to improve investment decision-making, and agility for responding to market changes and other external drivers. Visualisations capturing preferences were aligned with those in the portal, giving clients the opportunity to act on deposit recommendations, enabling a feedback loop for future suggestions."
            }
        ]
    },
    {
        title : "Resolving high-profile regulatory compliance issues through specialist consultancy and development",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "A tier-1 investment bank identified a number of issues with its operating model around the internal transfer of income and expenses in its global asset-management business. The issues had also been identified by the regulator, which raised a high-profile review point. Given the materiality of the issues, a high-level operational-risk item was raised at board level to highlight the compliance problems."
            },
            {
                title : "Approach",
                content : "Calibrate took charge of the existing remediation work and instigated a new governance structure, setting up and leading a steering committee and two separate working groups – one for the roll-out of the control framework, one for the design of the future landscape. We assumed budgetary responsibility, managed key stakeholders, and ensured that risks, issues and dependencies were discussed, while completing all action points."
            },
            {
                title : "Result",
                content : "By embedding a robust governance model within the project and identifying deficiencies in the existing process, we reduced the operational risk rating to the extent that it was no longer seen as an item for board-level attention. Controls were implemented throughout the front-to-back process to ensure the correct identification, calculation, payment and booking of revenue and expense transfers, including an additional post-month-end review."
            }
        ]
    },
    {
        title : "Undertaking an in-depth review of processes and roles to meet conditions for FCA submission deadline",
        badges : [],
        sections : [
            {
                title : "Problem",
                content : "After a period of rapid growth post-credit crunch, a tier-2 investment bank needed to prepare its CRV-IV (CAD1) renewal application to the FCA. As the deadline loomed larger, it became increasingly clear to bank executives that they did not have the expertise required to undertake the review and implement the necessary changes to ensure compliance with the regulation ahead of the deadline."
            },
            {
                title : "Approach",
                content : "A key member of the Calibrate team was installed as an interim Head of Product Control for equities, wealth management and asset management. Staff skills were assessed to ascertain development needs, and roles were redefined with full retraining provided. Day-to-day processes were reviewed to ensure unified, cohesive responses across operations, IT and senior management, and to identify and address any urgent issues."
            },
            {
                title : "Result",
                content : "In just 6 months, Calibrate successfully completed an in-depth review process that would typically take over a year. We represented the bank in all communications and meetings with the FCA, with the FCA interrogation initially scheduled for two sessions. After the first session, the bank received the FCA’s formal notification that a second session was unnecessary, accompanied by confirmation and that all conditions had been met and its authorisation application had been renewed."
            }
        ]
    },
]