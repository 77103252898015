import React from 'react';

import { Title, Paragraph, Badge } from '../../../components'

import styles from './styles.module.css';

export const CapibilityCard = ({ title , paragraphs, badges }) => (
    <div className={ styles.container }>
        <Title size="small">{ title }</Title>
        <div className={ styles.textContainer}>
        { paragraphs.map( (text, index) => (
            <Paragraph className={styles.noMarginBottom} key={index}>{ text }</Paragraph>
        ))}
        </div>
        
        <div className={styles.badgeContainer}>       
            {
                badges.map( label  => (
                    <Badge key={label} label={label}/>
                ))
            }
        </div>

    </div>
)