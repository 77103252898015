import React from 'react';

import styles from './styles.module.css';

export const Footer = () => {
    return (
        <div className={ styles.container }>
            <div className={ styles.logosContainer }>
                <img className={styles.calibrate} src="/calibrate-logo.svg"/>
                <div className={ styles.certifications}>
                    <img className={styles.certification} src="/certifications/cyber-essentials@2x.png"/>
                    <img className={styles.certification} src="/certifications/iso-9001@2x.png"/>
                    <img className={styles.certification} src="/certifications/iso-27001@2x.png"/>
                </div>
                
            </div>
            <div className={ styles.links}>
                <a className={ styles.link} href="/privacy-policy">
                    Privacy Policy
                </a>
                <a className={ styles.link} href="/terms-of-business">Terms of Business</a>
                <a className={ styles.link} href="/quality-policy">Quality and Information Security Policy</a>
                <a className={ styles.link} href="/cookies-policy">Cookies Policy</a>
                <a className={ styles.link} href="/terms-and-conditions">Site Terms and Conditions</a>
            </div>
            <span className={ styles.copyright }>Calibrate Consulting, Fifth Floor, Spice Building, 8 Devonshire Square, London EC2M 4PL | Phone: +44 (0) 3330 112844
            <br/>
                <span className={ styles.copyright }>All Rights Reserved  | © Calibrate Consulting 2020</span>
            </span>
        </div>
    )
}