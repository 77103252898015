import React from 'react';

import styles from './styles.module.css';

const iconsMapping = {
    gold : '/icons/gold-bars.svg',
    healthcare : '/icons/healthcare.svg',
    gas : '/icons/gas-rig.svg',
    retail : '/icons/retail.svg',
    museum : '/icons/museum.svg',
    factory : '/icons/factory.svg',

}

export const IndustryCard = ({ icon ="gold", text}) => {
    const iconUrl = iconsMapping[icon];
    return (
        <div className={styles.container}>
            <img
                 src={iconUrl}
            />
            <span className={styles.label}>{ text }</span>
        </div>
    )
}