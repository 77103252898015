import React from 'react';
import { StaticQuery, graphql } from "gatsby"


import { Title, Paragraph, NavBar } from '../../components'
import {MainLayout} from "../../layouts";
import Img from "gatsby-image"
import styles from './styles.module.css';


export const HeroSection = ({ className, children, headerImage }) => {
    console.log("headerImage : " , headerImage);
    return (
        <StaticQuery
            query={pageQuery}
            render={ data => {
                console.log(" data : " , data );
                return (
                <div className={ `${styles.container} ${className}`}>
                    <NavBar/>
                    <div className={styles.content}>
                        {children}
                    </div>

                </div>
            )}}
        />

    )
}

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "backgrounds/network.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`