import React from 'react';

import styles from './styles.module.css';

export const MainLayout = ({ children }) => {
    return (
        <div className={styles.container } id={"main-layout"}>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400&display=swap" rel="stylesheet"/>
            { children }
        </div>
    )
}