import React, { useState } from 'react';

import { LineDotContainer, Title } from '../../components'

import styles from './styles.module.css';

const quotes = [
    {
        quote : "We have seen a strong diversity  in your consultant team who have helped us on various aspects of our work, from operations, governance, policy maintenance to in depth and fundamental pricing of RMBS,CDOs, CRE Collateral and CMBS to name a few. Your staff members were always disciplined, strong team players and looked to add value",
        author : "Executive Director, Tier 1 Financial Services Client"
    },
    {
        quote : "Working with Calibrate Consulting is such a great experience. Calibrate Consulting has delivered two phases of the CEO Dashboard Project and every user of the dashboard is very satisfied",
        author: "Director, Performance Management Direction, National Oil Company"
    },
    {
        quote : "The professional and focussed approach together with the speed of delivery that makes working with Calibrate very rewarding",
        author: "Global Head of Liquidity IT, Global Investment Bank"
    },
    {
        quote : "It has been a breath of fresh air working with a company i can trust",
        author: "Head of IT Service Delivery, Wholesale Industry Retailer"
    },
    {
        quote : "Calibrate brings innovation and novel ways of delivering the latest Qlik technology in an enterprise environment, plus providing quality consultants with fantastic skills and a sense of humour!",
        author: "BI Project Manager, Tier 1 Investment Bank"
    },
]

export const CustomerFeedback = () => {
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

    const go = ( next = false) => {
        console.log("cur" , currentQuoteIndex );
        console.log("val : " , !!!next);
        const nextValue = next ? 1 : -1;
        if( currentQuoteIndex + nextValue < 0 ){
            setCurrentQuoteIndex(quotes.length - 1)
        }
        else {
            setCurrentQuoteIndex( (currentQuoteIndex + nextValue) % quotes.length )
        }


    }
    return (
        <div className={ styles.container }>
            <LineDotContainer className={styles.lineContainer}>
                <div className={styles.innerContainer}>
                    <Title className={ styles.title } size="small">Client quotes</Title>
                    <div className={styles.buttonsContainer}>
                        <img className={styles.arrowIcon}
                             src="/icons/arrow-left.svg"
                             onClick={ () => go()}
                        />
                        <img className={styles.arrowIcon}
                             src="/icons/arrow-right.svg"
                             onClick={ () => go(true)}
                        />
                    </div>

                </div>
                <span className={styles.quote}>
                        “{ quotes[currentQuoteIndex].quote }”
                </span>
                <Title className={styles.flex} size="tiny">{ quotes[currentQuoteIndex].author }</Title>


            </LineDotContainer>

        </div>
    )
}