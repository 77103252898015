import React, { useState, useEffect, createRef } from 'react';

import styles from './styles.module.css';


export const Accordion = ({ sections}) => {
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [activeSectionIndex,setActiveSectionIndex] = useState(0);
    let sectionRef = new Array(8);
    for( let i = 0 ; i < 8  ; i++ ){
        sectionRef[i] = createRef();
    }

    const activateSection = ( sectionIndex ) => {
        if( sectionIndex !== activeSectionIndex){

            console.log("srefs : " ,  sectionRef[sectionIndex].current.getBoundingClientRect())


            setActiveSectionIndex(sectionIndex);
        }
        else {
            if( sectionIndex === activeSectionIndex ){
                setActiveSectionIndex(undefined);
            }
        }
    };


    useEffect( () => {
        if( isFirstRun ){
            setIsFirstRun(false) ;
            return
        }

        if(!activeSectionIndex){
            return
        }

        var bodyRect = document.body.getBoundingClientRect(),
            elemRect = sectionRef[activeSectionIndex].current.getBoundingClientRect(),
            offset   = elemRect.top - bodyRect.top;

        window.scrollTo({
            left : 0,
            top : offset - 80,
            behavior : 'smooth'
        });

    }, [activeSectionIndex])



    return (
        <div className={styles.container}>
            { sections.map( ({ title , content }, sectionIndex) => (
                <div className={`${ styles.section}`}
                     key={sectionIndex}
                     ref={ sectionRef[sectionIndex]}>
                    <div className={`${styles.sectionTitle} ${ sectionIndex !== activeSectionIndex && styles.closed } `}
                        onClick={ () => activateSection(sectionIndex)}
                    >
                        <span className={styles.sectionNumber}>{sectionIndex+1}</span>
                        { title }
                        <span className={ styles.arrowIcon }>
                            <img src={`/icons/arrow-${ sectionIndex === activeSectionIndex ? "down" : 'left' }.svg`}/>
                        </span>
                    </div>
                    <div className={ `${styles.sectionContent} ${ sectionIndex !== activeSectionIndex && styles.hidden }`}>
                        { content }
                    </div>
                </div>
            ))}
        </div>
    )
}