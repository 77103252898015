import React from 'react';

import styles from './styles.module.css';
import { LineDotContainer, Title , Paragraph} from '../../components'

export const ValuePropositionSection = () => {
    return (
        <>
            <a name="about-us"/>
            <div className={ styles.container }>
                <LineDotContainer>
                    <div className={styles.innerContainer}>
                        <Title weight="thin">Linking data, transforming processes, <br/> creating exponential value</Title>
                        <div className={styles.textContainer2}>
                            <div className={styles.multiline}>
                                <Paragraph>
                                    We believe the people best placed to help other businesses succeed are those with hands-on experience in their specific industry. So, instead of career consultants, we are all experienced practitioners — leading, managing and driving our client projects with confidence.
                                </Paragraph>
                                <Paragraph>
                                    Technical expertise in data analytics, science, governance and management,
                                    industry experience and collaboration are fundamental to the way we operate
                                    — taking each client on their unique digital transformation journey.
                                </Paragraph>
                            </div>

                            <Paragraph className={styles.quote}>
                            “We listen to our clients, diving deep<br/>
                            into meeting their unique needs;
                                <br/>we combine technical expertise
                                <br/>and industry experience to deliver
                                <br/> innovative and sustainable solutions.”
                            </Paragraph>

                        </div>
                    </div>
                </LineDotContainer>
            </div>
        </>
    )
}