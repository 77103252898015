import React from 'react';
import { Title } from '../../components';

import styles from './styles.module.css';

export const Partners = ({ containerType = "downRight"}) => (
    <div className={styles.container}>
        <Title size="tiny" weight="bold">
            Our technology partners
        </Title>
        <div className={styles.imageContainer}>
            <img className={styles.image} src="/partners/qlik-elite.png"/>
            <img className={`${styles.image} ${ styles.denodo}`} src="/partners/denodo@2x.png"/>
            <img className={styles.image} src="/partners/data-robot@2x.png"/>
            <img className={styles.image} src="/partners/one-trust@2x.png"/>
            <img className={styles.image} src="/partners/ibm@2x.png"/>
        </div>
        <span className={ styles.quote }>
            "We only work with the best partners and service providers, <br/>
            but we’re led by our clients rather than specific products. <br/>
            If we can’t find the right technology solution we will build it for you."
        </span>
    </div>
);
