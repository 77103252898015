import React from 'react';

import styles from './styles.module.css'

const classesMapping = {
    large : styles.large,
    medium: styles.medium,
    small : styles.small,
    tiny : styles.tiny
};

const colourMapping = {
    "white" : styles.white,
    "black" : styles.black
};

const weightMapping = {
    "bold" : styles.bold,
    "normal" : styles.normal,
    "thin" : styles.thin
}

export const Title = ({ className,  children , size="medium" , colour = "black", weight = "normal", ...props }) =>{
    

    const sizeClass = classesMapping[size];
    const colorClass = colourMapping[colour];
    const weightClass = weightMapping[weight];

    return(
        <span { ...props } className={ `${styles.title} ${sizeClass} ${colorClass} ${weightClass} ${className}` }>{ children }</span>
    )
};
