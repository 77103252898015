import React from 'react';

import { LineDotContainer, Title } from '../../components'

import styles from './styles.module.css';

import { IndustryCard } from './IndustryCard/IndustryCard.js'

export const Industries = () => {
    return (
        <div className={styles.container}>
            <LineDotContainer containerType="rightDown">
                <Title size="small">Industries we serve</Title>
                <span className={styles.subtitle}>
                    Practitioner-led delivery, successfully implemented across a range of verticals.
                </span>
                <div className={ styles.cardContainer }>
                    <IndustryCard text="Financial Services"
                        icon="gold"
                    />
                    <IndustryCard text="Healthcare"
                        icon="healthcare"
                    />
                    <IndustryCard text="Oil and Gas"
                        icon="gas"
                    />
                    <IndustryCard text="Retail"
                            icon="retail"
                    />
                    <IndustryCard text="Public Sector"
                        icon="museum"
                    />
                    <IndustryCard text="Manufacturing"
                        icon="factory"
                    />
                </div>
            </LineDotContainer>
        </div>
    )
}