import React from 'react';

import styles from './styles.module.css';

import {SectionTitle, Title} from '../../components';

import { CapibilityCard } from './CapibilityCard/CapibilityCard.js';

export const Capabilities = () => {
    return (
        <>
            <a name="capabilities"/>
            <div className={ styles.container }>
                <SectionTitle name="Capabilities"/>

                <Title className={styles.title} weight="thin">Experts, bridging the gap between <br/>theory and application</Title>
                <div className={styles.cardsContainer}>
                    <CapibilityCard title="Specialist Digital Consultancy"
                        paragraphs={[
                            "Defining, building, optimising and managing the strategic interactions between business, new technology and data that drives transformation.",
                            "We deliver value for our clients immediately, bringing our expertise to consistently deliver innovative transformation solutions for our clients."
                        ]}
                        badges={[
                            "Data Strategy & Transformation",
                            "Business Architecture & Transformation",
                            "Process Design & Governance",
                            "Data Maturity & Literacy Assessments",
                            "Rapid Business Analysis",
                            "Operational Excellence",
                            "Risk & Quality Assessments"
                        ]}
                    />
                    <CapibilityCard title="System Integration"
                        paragraphs={[
                            "Working with and customising established, sophisticated technology to get the most out of your data and services.",
                            "We provide a wide range of services, combining established software with industry experience to create increasingly valuable systems and applications."
                        ]}
                        badges={[
                            "Enterprise & Solution Architecture",
                            "Data Analytics",
                            "Data Integration",
                            "AI & Data Science",
                            "Data Virtualisation",
                            "Risk & Quality Management",
                            "Data Governance",
                            "Application Development",

                        ]}
                    />
                </div>
                <div className={styles.cardsContainer}>
                    <CapibilityCard title="Managed Service Hosting"
                        paragraphs={[
                            "We don't just fix problems, we improve delivery, services and solutions, and create exponential value from existing systems.",
                            "Proactive support, ongoing maintenance and development, and consultancy services for custom and 3rd party developed solutions and environments."
                        ]}
                        badges={[
                            "Qlik Support and Maintenance",
                            "Qlik Apps Hosting",
                            "Managed Analytical Services",
                            "Kubernetes",
                            "Application health check"
                        ]}
                    />
                    <CapibilityCard title="Digital Products"
                        paragraphs={[
                            "With top-tier R&D capabilities, we innovate, improve and develop new solutions to meet the needs of our clients.",
                            "End-to-end business information management, enabling our clients to make effective use of information for improved decision making and collaboration."
                        ]}
                        badges={[
                            "Care Hub",
                            "Calibrate Data Services",
                            "Calibrate Data Capture"
                        ]}
                    />
                </div>
            </div>
        </>
    )
}